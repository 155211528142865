import React from "react"
import Layout from "../layouts"

import { SEO, DesktopProjectsView, MobileProjectsView } from "../components"
import { useWindowSize } from "../hooks"
import { AppContext } from "../providers"

const ProjectsNew = ({ location }) => {
  const { width } = useWindowSize()

  const { setContainer } = React.useContext(AppContext)

  React.useEffect(() => {
    if (location.state && location.state.project === "Maths") {
      setContainer(1)
    }
  }, [])

  return (
    <>
      <SEO title="Projects" />
      <Layout>
        {width >= 1300 ? (
          <DesktopProjectsView />
        ) : (
          <MobileProjectsView
            state={location.state ? location.state.project : null}
          />
        )}
      </Layout>
    </>
  )
}

export default ProjectsNew
